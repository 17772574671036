import React from 'react';
import CustomLayout from '../CustomLayout';
import image1 from 'images/pages/origenencuesta1';

const OrigenEncuesta = () => {
  return (
    <CustomLayout>
      <h2 className='section-title'>POR QUÉ NACE ESTA ENCUESTA</h2>      
      <p className='section-subtitle'>
      En nuestro compromiso, te ofrecemos la oportunidad de participar en una encuesta diseñada para entender mejor vuestras necesidades formativas específicas. Sabemos que el sector sanitario está siempre en constante cambio, y adquirir nuevos conocimientos y habilidades es clave para ofrecer la mejor atención posible. Al pedir tu opinión directa, nos aseguramos de adaptar nuestros programas de formación para abordar las áreas que más afecten tu práctica diaria.
        <br />
        <div className="custom_page_image_container">
          <img className="custom_page_image" src={image1} alt="origenencuesta1" />          
        </div> 
        <br />
        Esta iniciativa refleja nuestro auténtico compromiso con tu bienestar y desarrollo profesional. Al pensar en tí, buscamos no solo satisfacer tus necesidades actuales, sino también anticiparnos a los desafíos futuros en el ámbito de la salud. Valoramos tu experiencia y dedicación, y la encuesta destinada a identificar tus necesidades formativas es un paso crucial para proporcionarte las herramientas necesarias para seguir brindando atención sanitaria de la más alta calidad.
      </p>      
    </CustomLayout>
  );
};

export default OrigenEncuesta;
