import React, { useState } from 'react';
import { Menu } from 'antd';
import { LinkedinFilled, FacebookFilled, TwitterSquareFilled, InstagramFilled } from '@ant-design/icons';

const items = [
  {
    label: '',
    key: 'linkedin',
    icon: <LinkedinFilled />,
    link: 'https://www.linkedin.com/',
  },
  {
    label: '',
    key: 'facebook',
    icon: <FacebookFilled />,
    link: 'https://www.facebook.com/',
  },
  {
    label: '',
    key: 'twitter',
    icon: <TwitterSquareFilled />,
    link: 'https://twitter.com/',
  },
  {
    label: '',
    key: 'instagram',
    icon: <InstagramFilled />,
    link: 'https://www.instagram.com/',
  },
];

const SocialHeader = () => {
  const [current, setCurrent] = useState('');

  const onClick = (e) => {    
    setCurrent(e.key);
  };

  return (
    <Menu className="socialmenu d-none" onClick={onClick} mode="horizontal" style={{ position: 'fixed', marginTop: '-50px', right: '50px', border: 'none' }}>
      {items.map((item) => (
        <Menu.Item key={item.key}>
          <a href={item.link} target="_blank" rel="noopener noreferrer">
            {item.icon} {item.label}
          </a>
        </Menu.Item>
      ))}
    </Menu>
  );
};

export default SocialHeader;