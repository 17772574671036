import React from 'react';
import CustomLayout from '../CustomLayout';
import image1 from 'images/pages/ects1';
import image2 from 'images/pages/ects2';


const CreditosEcts = () => {
  return (
    <CustomLayout>      
      <h2 className='section-title'>CRÉDITOS ECTS</h2>            
      <p className='section-subtitle'>
      Los Créditos ECTS, o Sistema Europeo de Transferencia y Acumulación de Créditos, constituyen un marco estandarizado utilizado en el ámbito educativo superior para medir el volumen total de trabajo académico necesario para completar un curso. En el contexto del Hospital Centro Médico de Asturias, nos complace informar que los cursos de formación están cuidadosamente estructurados y acreditados con Créditos ECTS. Esta acreditación no solo valida la calidad de la formación impartida, sino que también te proporcionará un reconocimiento formal del esfuerzo y la dedicación invertidos por ti y por el equipo que participa en estos programas formativos.
        <br />
        <div className="custom_page_image_container">
          <img className="custom_page_image" src={image1} alt="ects1" />          
        </div>        
        <br />
        La decisión de acreditar los cursos con Créditos ECTS responde al compromiso de brindarte una formación rigurosa y reconocida a nivel internacional. Esta medida no solo enriquecerá tus conocimientos y habilidades, sino que también te permitirá contar con un respaldo académico que tiene validez más allá de nuestras fronteras. Valoramos profundamente la formación continua y la mejora profesional, y esta acreditación refleja el compromiso con el crecimiento y el desarrollo continuo de nuestro valioso personal sanitario.
      </p>
    </CustomLayout>
  );
};

export default CreditosEcts;
