import React from 'react';
import { Button } from 'antd';

const CourseTypeButton = ({ type, selected, onClick, activeIcon, inactiveIcon }) => (
  <Button
    type="primary"
    className={selected ? 'course-type-button active' : 'course-type-button'}
    onClick={onClick}
  >
    <img className="icon" src={selected ? activeIcon : inactiveIcon} alt="course type Icon" />    
    <div className='text'>
        {type}
    </div>
  </Button>
);

export default CourseTypeButton;