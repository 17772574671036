import React from 'react';
import { Layout, Menu } from 'antd';
import { NavLink, Link, useLocation } from 'react-router-dom';
import 'antd/dist/antd.css';
import 'bootstrap/dist/css/bootstrap.min.css';
import 'bootstrap-icons/font/bootstrap-icons.css';
import '../src/App.scss';
import logo from 'images/logo_centromedico';
import SocialHeader from './header/SocialHeader';

const { Header, Content, Footer } = Layout;

const CustomLayout = ({ children }) => {

  const location = useLocation();

  const handleRefresh = () => {
    if (location.pathname === '/') {
      window.location.reload(true);
    }
  };


  return (
    <Layout className="bg-white">      
      <Header style={{ position: 'fixed', zIndex: 1, width: '100%', display: 'flex', justifyContent: 'space-between' }}>
        <div>
          <SocialHeader />
        </div>
        <div className="d-flex w-100">
          <div className="logo" style={{ height: '100px', margin: 'auto 0px'}}>
            <Link to="/" onClick={handleRefresh}>
              <img className="h-100" src={logo} alt="Company Logo" />
            </Link>
          </div>
          <Menu theme="light" mode="horizontal" defaultSelectedKeys={['1']}>
            <Menu.Item key="1">
              <NavLink to="/queremos-centrarnos-en-ti" exact="true" activeclassname="active-link">Queremos centrarnos en TI</NavLink>
            </Menu.Item>  
            <Menu.Item key="2">
              <NavLink to="/la-importancia-de-la-formacion" exact="true" activeclassname="active-link">La importancia de la formación</NavLink>
            </Menu.Item>  
            <Menu.Item key="3">
              <NavLink to="/por-que-nace-esta-encuesta" exact="true" activeclassname="active-link">¿Por qué nace esta encuesta?</NavLink>
            </Menu.Item>  
            <Menu.Item key="4">
              <NavLink to="/que-son-los-creditos-ects" exact="true" activeclassname="active-link">¿Qué son los créditos ECTS?</NavLink>
            </Menu.Item>          
          </Menu>
        </div>
        
      </Header>
      <Content className="container" style={{ padding: '30px 50px', marginTop: 150 }}>
        {children}        
      </Content>
      {/* <Footer style={{ textAlign: 'center' }}>4Doctors ©2024</Footer> */ }
    </Layout>
  );
};

export default CustomLayout;
