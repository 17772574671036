// SpecialtyButton.jsx
import React from 'react';
import { Button } from 'antd';

const SpecialtyButton = ({ specialty, selected, onClick, activeIcon, inactiveIcon }) => (
  <Button
    type="primary"
    className={selected ? 'specialty-button active' : 'specialty-button'}
    onClick={onClick}
  >
    <img className="icon" src={selected ? activeIcon : inactiveIcon} alt="specialty Icon" />
    <div className='text'>
      {specialty}
    </div>
  </Button>
);

export default SpecialtyButton;
