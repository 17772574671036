import React from 'react';
import './NotFound.css'; // Import the custom styles
import logo from 'images/logo_centromedico';

const NotFound = () => {
  return (
    <div className="not-found-container">
      <div className="not-found-content">
      <img src={logo} alt="Company Logo" style={{width: '200px', margin: '1rem 0px'}}/>    
        <h1 className="not-found-heading">404 - No Encontrado</h1>
        <p className="not-found-text">
          Lo siento, la página que buscas no existe.
        </p>
        <a href="/" className="not-found-link">
          Volver a la página principal
        </a>
      </div>
    </div>
  );
};

export default NotFound;