import React, { useState } from 'react';
import { Form, Input, Button } from 'antd';
import CategoryIcon from './CategoryIcon';
import SpecialtyButton from './SpecialtyButton';
import CourseTypeButton from './CourseTypeButton';
import ActiveSpecialtyIcon from 'images/icons/activespecialtyicon.png';
import InactiveSpecialtyIcon from 'images/icons/specialtyicon.png';
import ActiveCourseTypeIcon from 'images/icons/activecoursetypeicon.png';
import InactiveCourseTypeIcon from 'images/icons/coursetypeicon.png';


function SpecialtyForm({ onFinish, selectedCategory, setSelectedSpecialties, setSelectedCourseType, categories }) {
  const [form] = Form.useForm();
  const [localSelectedSpecialties, setLocalSelectedSpecialties] = useState([]);
  const [localSelectedCourseType, setLocalSelectedCourseType] = useState('');
  
  const handleFinish = (values) => {
    onFinish({ ...values, specialities: localSelectedSpecialties });
  };
  
  const specialtiesFromCategorySelected = categories.find(category => category.name === selectedCategory.name)?.specialities || [];      
  const specialities = specialtiesFromCategorySelected.map(specialty => specialty.name);    

  const courseTypes = categories.find(category => category.name === selectedCategory.name)?.course_type || []; 

  const handleSpecialtyClick = (specialty) => {
    const updatedSpecialties = localSelectedSpecialties.includes(specialty)
      ? localSelectedSpecialties.filter((selected) => selected !== specialty)
      : [...localSelectedSpecialties, specialty];

    if (updatedSpecialties.length <= 2) {
      setLocalSelectedSpecialties(updatedSpecialties);
    }
  };

  const handleCourseTypeClick = (value) => {
    setLocalSelectedCourseType(value);
    form.setFieldsValue({ course_type: value });
  };

  const validateSpecialties = () => {
    if (localSelectedSpecialties.length === 0) {
      return Promise.reject(new Error('Debes seleccionar al menos una especialidad.'));
    }
    return Promise.resolve();
  };

  const validateCourseType = () => {
    if (!localSelectedCourseType) {
      return Promise.reject(new Error('Debes seleccionar un tipo de curso.'));
    }
    return Promise.resolve();
  };

  return (
    <div className='section-encuesta-principal'>
      <div className='d-flex justify-content-between'>
        <div>
          <h2 className='section-title text-start'>{selectedCategory.name}</h2>
          <h4 className='section-subtitle text-start'>¿Qué especialidades te parecen más interesantes para tu formación?
          <br />(Puedes seleccionar hasta 2)</h4>
        </div>
        <div>
          <div className="category-icon">
            <div type="primary" className="circle-button">
              <CategoryIcon icon={selectedCategory.icon} />
            </div>
          </div>                  
        </div>
      </div>
      
      <Form form={form} onFinish={handleFinish}>
        <div className='mb-5'>
          <Form.Item
            name='specialities'
            initialValue={localSelectedSpecialties}
            rules={[
              { validator: validateSpecialties }
            ]}
          >
            <div className='justify-content-between'>
            {specialities.map((specialty) => (
              <SpecialtyButton
                key={specialty}
                specialty={specialty}
                selected={localSelectedSpecialties.includes(specialty)}
                onClick={() => handleSpecialtyClick(specialty)}
                activeIcon={ActiveSpecialtyIcon}
                inactiveIcon={InactiveSpecialtyIcon}
              />
            ))}
            </div>
          </Form.Item>
        </div>
        
        { /* show next div if selectedCategory.name is Enfermería y Auxiliares or Celadores */ }
        { selectedCategory.name === 'Enfermería y Auxiliares' || selectedCategory.name === 'Celadores' || selectedCategory.name === 'Medicina' ? 
          <div className='mb-5'>
            <h4 className='section-subtitle text-start'>¿Qué tipo de formación se adapta mejor a tus necesidades?</h4>
            <Form.Item
              name='course_type'
              initialValue={localSelectedCourseType}
              rules={[
                { validator: validateCourseType }
              ]}
            >
              <div className='justify-content-between'>
                {courseTypes.map((type) => (
                  <CourseTypeButton                
                    key={type.name}
                    type={type.name}
                    selected={localSelectedCourseType === type.name.toLowerCase()}
                    onClick={() => handleCourseTypeClick(type.name.toLowerCase())}
                    activeIcon={ActiveCourseTypeIcon}
                    inactiveIcon={InactiveCourseTypeIcon}
                  />
                ))}
              </div>
            </Form.Item>
          </div> : 
          <div className='mb-5'>
            <h4 className='section-subtitle text-start'>Indica tu profesión</h4>
            <Form.Item
              name='profesion'
              rules={[
                { required: true, message: 'Por favor, indica tu profesión.' }
              ]}>
              <Input />
            </Form.Item>
          </div> 
        }
      

        <div className='mb-5'>
          <h4 className='section-subtitle text-start'>¿Hay algún curso en concreto que te gustaría hacer? (Opcional)</h4>
          <Form.Item name='comments'>
            <Input.TextArea />
          </Form.Item>
        </div>

        <Button className="submit-button" type="primary" htmlType="submit">Enviar</Button>
      </Form>                  
    </div>
  );
}

export default SpecialtyForm;
