import React from 'react';
import { Routes, Route } from 'react-router-dom';
import MainForm from '../components/form/MainForm';
import Ti from '../components/pages/Ti';
import ImportanciaFormacion from '../components/pages/ImportanciaFormacion';
import OrigenEncuesta from '../components/pages/OrigenEncuesta';
import CreditosEcts from '../components/pages/CreditosEcts';

// Import the 404 component
import NotFound from '../components/pages/NotFound';

const AppRoutes = () => {
  return (
    <Routes>
      <Route path="/" element={<MainForm />} />
      <Route path="/queremos-centrarnos-en-ti" element={<Ti />} />
      <Route path="/la-importancia-de-la-formacion" element={<ImportanciaFormacion />} />
      <Route path="/por-que-nace-esta-encuesta" element={<OrigenEncuesta />} />
      <Route path="/que-son-los-creditos-ects" element={<CreditosEcts />} />

      {/* Route for handling 404 errors */}
      <Route path="*" element={<NotFound />} />
    </Routes>
  );
};

export default AppRoutes;
