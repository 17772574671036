import React, { useState } from 'react';
import { Form, Input, Button } from 'antd';
import CategoryButton from './CategoryButton';

function CategoryForm({ onFinish, setSelectedCategory, categories }) {
  const [form] = Form.useForm();

  const handleCategoryClick = (category) => {    
    setSelectedCategory(category);
    form.setFieldsValue({ category: category });
    form.submit();
  };

  return (
    <div className='section-encuesta-principal'>
      <h2 className='section-title text-center'>Encuesta Para Detección de <br />Necesidades Formativas 2024</h2>
      <h4 className='section-subtitle text-center'>¿A qué categoría profesional perteneces?</h4>

      <Form form={form} onFinish={() => onFinish(form.getFieldValue('category'))}>
        {categories.map((category) => (
          <Form.Item key={category.name} name="category" initialValue={category.name} hidden>
            <Input />
          </Form.Item>
        ))}
      </Form>

      <div className='mt-5 justify-content-center categories-div-section'>
      {categories.map((category) => (
          <CategoryButton
            key={category.name}
            categoryName={category.name}
            onClick={() => handleCategoryClick(category)}
            icon={category.icon}
          />
        ))}
      </div>
    </div>
  );
}

export default CategoryForm;
