import React from 'react';
import CustomLayout from '../CustomLayout';
import image1 from 'images/pages/ti1';
import image2 from 'images/pages/ti2';
import image3 from 'images/pages/ti3';
import image4 from 'images/pages/ti4';

const Ti = () => {
  return (
    <CustomLayout>      
      <h2 className='section-title'>QUEREMOS CENTRARNOS EN TI</h2>            
      <p className='section-subtitle'>
      En este inicio de 2024, queremos darte las gracias como miembro de nuestro equipo del Hospital Centro Médico de Asturias por el increíble trabajo realizado hasta el momento. Con el lema <i><strong><u>"Queremos centrarnos en tí"</u></strong></i>, queremos resaltar tu profesionalidad y plena dedicación. 
        <br />
        <div className="custom_page_image_container">
          <img className="custom_page_image" src={image1} alt="ti1" />          
        </div> 
        <br />
        Cada miembro del equipo, habéis demostrado una gran actitud, brindando cuidados con empatía y valentía y siendo un apoyo fundamental en estos tiempos difíciles. Desde nuestro Hospital, sabemos que el enfoque en el paciente es fundamental y ha de estar respaldado con una potente formación de nuestro equipo, es por ello que “Queremos centrarnos en tí”; brindarte formación de calidad y acreditada es nuestro reconocimiento. ¡Gracias! 

      </p>

    </CustomLayout>
  );
};

export default Ti;
