import React from 'react';
import Icon1 from 'images/icons/admn.png';
import Icon2 from 'images/icons/nurse.png';
import Icon3 from 'images/icons/celadores.png';
import Icon4 from 'images/icons/bioquimica.png';
import Icon5 from 'images/icons/personal-tecnico.png';
import Icon6 from 'images/icons/medicina.png';

const iconMapping = [Icon1, Icon2, Icon3, Icon4, Icon5, Icon6];

const CategoryIcon = ({ icon }) => {
  const iconIndex = parseInt(icon, 10) - 1; // Assuming icons are 1-indexed
  const IconComponent = iconMapping[iconIndex];

  return <img src={IconComponent} alt={icon} />      
};

export default CategoryIcon;
