import React from 'react';
import { Button } from 'antd';
import CategoryIcon from './CategoryIcon';

const CategoryButton = ({ categoryName, onClick, icon }) => {
  return (
    <div className="category-button">
      <Button type="primary" className="circle-button" onClick={onClick}>
        <CategoryIcon icon={icon} />
      </Button>
      <div className="category-name">{categoryName}</div>
    </div>
  );
};

export default CategoryButton;