import React, { useState, useEffect } from 'react';
import CustomLayout from '../CustomLayout';
import { Steps, message } from 'antd';
import CategoryForm from './CategoryForm';
import SpecialtyForm from './SpecialtyForm';
import CourseSelectionForm from './CourseSelectionForm';
import ThankYouPage from './ThankYou';

function MainForm() {
  const [current, setCurrent] = useState(0);
  const [selectedCategory, setSelectedCategory] = useState(null);
  const [formData, setFormData] = useState({});
  const [formSubmitted, setFormSubmitted] = useState(false);
  const [selectedSpecialties, setSelectedSpecialties] = useState([]);
  const [selectedCourseType, setSelectedCourseType] = useState('');
  const [selectedCourses, setSelectedCourses] = useState([]);
  const [data, setData] = useState({
    categories: [],
    recommendedCourses: [],
  });


  const loadCategories = () => {
    const url = "api/v1/categories";
    fetch(url, {
      headers: {
        Accept: "application/json, text/plain, */*",
      },
    })
      .then((response) => {
        if (!response.ok) {
          throw new Error('Network error.');
        }
        return response.json();
      })
      .then((data) => {
        setData(data);
      })
      .catch((err) => {
        console.error("Error fetching categories:", err);
        message.error("Error: " + err.message);
      });
  };

  useEffect(() => {
    loadCategories();
  }, []); // Empty dependency array ensures the effect runs once on mount


  const saveCategories = (values) => {    
    console.log(values);
    const url = "api/v1/form_infos/create_form_info";
    fetch(url, {
      method: "POST",
      headers: {
        Accept: "application/json, text/plain, */*",
        "Content-Type": "application/json", 
      },
      body: JSON.stringify(values),
    })
    .then((response) => {
      if (!response.ok) {
        throw new Error('Network error.');
      }
      return response.json();
    })
    .catch((err) => {
      console.error("Error fetching categories:", err);
      message.error("Error: " + err.message);
    });
  };


  const onFinishCategoryForm = (category) => {    
    setSelectedCategory(category);
    setCurrent(1);
  
    // Scroll to the top of the page
    window.scrollTo({ top: 0, behavior: 'smooth' });
  };
  

  const onFinishSpecialtyForm = (values) => {    
    /*
    console.log("Llamada a la API 1:", {
      category: selectedCategory.name,
      ...formData,
      ...values,
    });
    */
    setFormData({
      ...formData,
      category: selectedCategory.name,
      ...values,
    });    
    
    setCurrent(2);

    // Scroll to the top of the page
    window.scrollTo({ top: 0, behavior: 'smooth' });
  };

  const onFinishCourseSelection = (courseData) => {    
    console.log("Llamada a la API 2:", {
      category: selectedCategory.name,
      ...formData,
      courses: courseData,
    });
    setFormSubmitted(true);


    saveCategories({
      category: selectedCategory.name,
      ...formData,
      courses: courseData,
    });

    // Scroll to the top of the page
    window.scrollTo({ top: 0, behavior: 'smooth' });
  };

  const isStepDisabled = (stepNumber) => {
    if (stepNumber === 0) {
      return false;
    }
    return selectedCategory === null;
  };

  const forms = [
    <CategoryForm categories={data.categories} onFinish={onFinishCategoryForm} setSelectedCategory={setSelectedCategory} />,
    <SpecialtyForm
      onFinish={onFinishSpecialtyForm}
      selectedCategory={selectedCategory}
      setSelectedSpecialties={setSelectedSpecialties}
      setSelectedCourseType={setSelectedCourseType}
      categories={data.categories}
    />,
    <CourseSelectionForm
      onFinish={onFinishCourseSelection}
      selectedCategory={selectedCategory}
      selectedSpecialties={formData.specialities}
      setSelectedCourses={setSelectedCourses}
      recommendedCourses={data.recommendedCourses}
      categories={data.categories}
    />   
  ];

  return (
    <CustomLayout>
      <Steps onChange={setCurrent} current={current}>
        <Steps.Step disabled={isStepDisabled(0)} title="Categorias" />
        <Steps.Step disabled={isStepDisabled(1)} title="Especialidades" />
        <Steps.Step disabled={isStepDisabled(2)} title="Seleccionar Cursos" />
      </Steps>

      {formSubmitted ? (
        <ThankYouPage />
      ) : (
        forms[current]
      )}
    </CustomLayout>
  );
}

export default MainForm;
