import React from 'react';
import logo from 'images/thankyou_logo';

const ThankYouPage = () => {

    return (
        <div className='section-encuesta-principal'>
            <h2 className='section-title text-center'>¡Muchísimas gracias!</h2>
            <h4 className='section-subtitle text-center thankyou'>Gracias a ti, en este año tendremos una formación mucho más centrada en vuestras necesidades</h4>
            <img className="w-50 d-flex m-auto" src={logo} alt="Company Logo" />
        </div>
    );
  };
  
  export default ThankYouPage;