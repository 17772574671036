import React, { useState } from 'react';
import { Button, Checkbox } from 'antd';
import CategoryIcon from './CategoryIcon';

const CourseSelectionForm = ({ onFinish, selectedCategory, setSelectedCourses, recommendedCourses, selectedSpecialties, categories}) => {
  const [localSelectedCourses, setLocalSelectedCourses] = useState([]);  


  const specialtiesFromCategorySelected = categories.find(category => category.name === selectedCategory.name)?.specialities || [];  
  
  //courses from selectedSpecialties from category selected
  const coursesFromSpecialtiesFromCategorySelected_1 = specialtiesFromCategorySelected.find(specialty => specialty.name === selectedSpecialties[0])?.courses || [];
  const coursesFromSpecialtiesFromCategorySelected_2 = specialtiesFromCategorySelected.find(specialty => specialty.name === selectedSpecialties[1])?.courses || [];  
  
  const courseLists = [selectedCategory.recommended_courses, coursesFromSpecialtiesFromCategorySelected_1,coursesFromSpecialtiesFromCategorySelected_2];  
  
  const handleCourseClick = (course) => {
    const updatedCourses = localSelectedCourses.includes(course)
      ? localSelectedCourses.filter((selected) => selected !== course)
      : [...localSelectedCourses, course];

    setLocalSelectedCourses(updatedCourses);
  };

  return (
    <div className='section-encuesta-principal'>
      <div className='d-flex justify-content-between'>
        <div>
          <h2 className='section-title text-start'>{selectedCategory.name}</h2>
          <h4 className='section-subtitle text-start'>
            Por último, hemos elaborado una lista con cursos que creemos que pueden parecerte interesantes para este año.
            <br />
            <br />
            ¿Nos ayudas marcando los que te gustaría realizar?
          </h4>
        </div>
        <div>
          <div className="category-icon">
            <div type="primary" className="circle-button">
              <CategoryIcon icon={selectedCategory.icon} />
            </div>
          </div>
        </div>
      </div>

      <div className='d-flex my-5 justify-content-between row'>
        {courseLists.map((courses, index) => (                  
          <div key={index} className='col-md-4 course-column'>
            {index === 0 && courses.length > 0 && <h4>Cursos Propuestos por el centro</h4>}
            {index !== 0 && courses.length > 0 && <h4>{selectedSpecialties[index-1]}</h4>}
            {courses.map((course) => (
              <Checkbox
                key={course.name}
                className='course-checkbox'
                checked={localSelectedCourses.includes(course.name)}
                onChange={() => handleCourseClick(course.name)}
              >
                {course.name}
              </Checkbox>
            ))}
          </div>          
        ))}
      </div>

      <Button className="submit-button" type="primary" onClick={() => onFinish(localSelectedCourses)}>
        Enviar
      </Button>
    </div>
  );
}

export default CourseSelectionForm;
