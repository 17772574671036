// app/javascript/packs/index.jsx

import React from 'react';
import { createRoot } from 'react-dom/client';
import { BrowserRouter } from 'react-router-dom';
import AppRoutes from '../routes'; // Adjust the import path

document.addEventListener('DOMContentLoaded', () => {
  const rootElement = document.getElementById('root');
  const root = createRoot(rootElement);

  if (!root._hasInitialRender) {
    root.render(
      <React.StrictMode>
        <BrowserRouter>
          <AppRoutes />
        </BrowserRouter>
      </React.StrictMode>
    );
  } else {
    root.render(
      <React.StrictMode>
        <BrowserRouter>
          <AppRoutes />
        </BrowserRouter>
      </React.StrictMode>
    );
  }
});
