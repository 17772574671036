import React from 'react';
import CustomLayout from '../CustomLayout';
import image1 from 'images/pages/importanciaformacion1';

const ImportanciaFormacion = () => {
  return (
    <CustomLayout>
      <h2 className='section-title'>LA IMPORTANCIA DE LA FORMACIÓN</h2>            
      <p className='section-subtitle'>
      En el Hospital Centro Médico de Asturias, valoramos y celebramos tu dedicación constante a la formación y actualización profesional. Reconocemos la importancia de mantenerte al tanto de los avances en salud y sabemos que este enfoque no solo garantiza altos estándares profesionales, sino que también asegura que puedas aplicar las últimas innovaciones y tratamientos para el beneficio de quienes confían en nosotros.
        <br />
        <div className="custom_page_image_container">
          <img className="custom_page_image" src={image1} alt="importanciaFormacion" />          
        </div> 
        <br />
        Necesitamos respaldarte y poder facilitarte oportunidades de formación para tí y tu equipo de trabajo. Al alentar y respaldar tu formación constante, reafirmamos nuestro compromiso con la excelencia en la atención asistencial y la constante búsqueda de mejores prácticas, para el beneficio de todos nuestros pacientes.
      </p>
    </CustomLayout>
  );
};

export default ImportanciaFormacion;
